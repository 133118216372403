import React, { memo, useEffect, useState } from 'react'
import {CSSTransition} from 'react-transition-group';
import '../css/web.css'
function Specs(props) {
    //const [trigger, setTrigger] = useState(false)
    const {section, exit} = props;
    const [trigger, setTrigger] = useState(false)
    const [sectionSelected, setSectionSelected] = useState(section)
    setTimeout(() => {
        if(trigger==false ){
            setSectionSelected(section)
            setTrigger(true)
        }
    }, 5000);
    if(sectionSelected!=section&&trigger == true){
        setTimeout(() => {
                setTrigger(false)
            }, 50);
    }
    const getSectionText = ()=>{
            switch(sectionSelected){
                case 1: 
                    return <div> <span>Blockchain</span>AI/ML<span>Cloud</span> Automation <span>IoT</span>  SaaS <span> Analitycs </span> Big Data <span>Apps</span> Web/Móviles  <span> AR </span> DEVOPS  <span> Security </span> </div>
                case 2: 
                    return <div><span>Branding</span> WebSites  <span>Design Thinking</span> UI/UX <span> Typography </span> 3D <span>Animations</span> Characters <span> Videos </span> Fotografia <span> Product Design </span> Packaging <span> Impresos </span></div>
                case 3: 
                    return <div><span>MKT Digital</span> Campañas <span> Sales-Grow </span> Aceleración <span> Incubación </span> Legal <span> Estrategia </span> Culture <span> BusinessPlan </span> Transformation <span> Reorganizacion </span> </div>
            }
    }
    return (
        <section className="sectionBG">
            <div className="scroll text">   
            <CSSTransition
                unmountOnExit
                in={trigger}
                timeout={2000}
                classNames="my-node"
            >
            {getSectionText()}
                 </CSSTransition>
            </div>

        </section>
    )
}

export default Specs
