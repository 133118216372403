import React from 'react'
import { useParams } from 'react-router';
import VideoBackground from '../xyn/UIComponents/VideoBackground';

function SiteVideoBackground(params) {
    const {currentSection, nextSection} =  params;
    const path = "/media/"+currentSection+"-"+nextSection+".mp4";
    return (
        <div style={{ position: "absolute", width:"100%", height:"100%"}}>
            <VideoBackground path={path} delay={true}/>
            <VideoBackground path={path} delay={false} onVideoComplete={()=>{params.onVideoComplete(nextSection)}} onLoadVideo={()=>{params.onLoadVideo(nextSection)}} />
            <div style={{ zIndex:"-1", position: "absolute", width:"100%", height:"100%",  backgroundColor:"rgba(0, 30, 60, 0.3)"}} ></div>
        </div>

    )
}
export default SiteVideoBackground