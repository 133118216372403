
import { Layout, Button, Row, Col, Space,  Typography , Divider} from 'antd';
import React, { useState } from 'react'
import {CSSTransition} from 'react-transition-group';
import '../css/web.css'
function ContentSection(params) {
    const { Title } = Typography;
    const {section} = params;
    const [trigger, setTrigger] = useState(false)
    const [sectionSelected, setSectionSelected] = useState(section)
    const [mem, setMem] = useState({section:section,antSection:0,  titulo:"Innovación tecnológica", descripcion:"Alcanza el máximo potencial de tu empresa innovando con nuevas tecnologías que aceleran tu dominancia en el mercado presente y futuro."});
   
    setTimeout(() => {
        if(trigger==false ){
            changeSection(section)
            setSectionSelected(section)
            setTrigger(true)
        }
    }, 5000);
    if(sectionSelected!=section&&trigger == true){
        setTimeout(() => {
                setTrigger(false)
            }, 50);
    }
    

    const changeSection = (sectionID) => {
       if(sectionID==mem.section){
            return 
        }
        const antSection  = mem.section;
        switch(sectionID){
            case 1:
                setMem({...mem,  antSection, section:sectionID,  titulo:"Innovación tecnológica", descripcion:"Alcanza el máximo potencial de tu empresa innovando con nuevas tecnologías que aceleran tu dominancia en el mercado presente y futuro."});
                break;
            case 2:
                setMem({...mem, antSection, section:sectionID, titulo:"Diseño innovador", descripcion:"Empodera tu negocio con diseños de vanguardia, transformando la forma en que te ven tus clientes para decubrir nuevas oportunidades de negocios."});
                break;
            case 3:
                setMem({...mem, antSection, section:sectionID, titulo:"Desarrollo innovador de negocios", descripcion:"Estrategias ágiles que suman ventaja comercial a través de modelos operativos resilentes con visión a largo plazo y soluciones a la medida que garantizan el crecimiento de tu negocio."});
                break;
        }
    }

    return (
        <CSSTransition
                unmountOnExit
                in={trigger}
                timeout={2000}
                classNames="my-node">
            <Col span={10}>
                <Title level={4}>{mem.titulo}</Title>
                <Title  style={{color:"#888"}} level={4}>{mem.descripcion}</Title>
                <div style={{height:"50px"}} ></div>
                <Typography.Link style={{color:"#FFF"}} underline>CONOCE MÁS</Typography.Link>
            </Col>
        </CSSTransition>
    )
}

export default ContentSection

