import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router';

function VideoBackground(props) {

    const {path, delay} =  props;

    const url = path;
    const videoRef = useRef();
    const previousUrl = useRef(url);
    useEffect(() => {
        previousUrl.current = url;
        if(delay==true){
            setTimeout(() => {              
                  if (videoRef.current) {
                    videoRef.current.load();
                  }              
            }, 500);
        }else{
            if (videoRef.current) {
            videoRef.current.load();
            }        
        }
      }, [url]);
    return (
        <video  ref={videoRef}
            onEnded={()=>{if(props.onVideoComplete){
                props.onVideoComplete();
            }}}
            onLoadedData={()=>{if(props.onLoadVideo){
                props.onLoadVideo();
            }}}
            autoPlay
            muted
            style={{
                position: "absolute", 
                width:"100%",
                left:"50%",
                top:"50%",
                height:"100%",
                objectFit:"cover",
                transform:"translate(-50%,-50%)",
                zIndex:"-1"
            }}>
            <source src={url}  type="video/mp4"/>
        </video>
    )
}

export default VideoBackground
