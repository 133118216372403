import { Layout, Button, Row, Col, Space,  Typography , Divider} from 'antd';
import "antd/dist/antd.dark.css";
import '../css/web.css'
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import React, { memo, useEffect, useState } from 'react';
import AnimateCC, { GetAnimationObjectParameter } from "react-adobe-animate";
import SiteVideoBackground from '../site/SiteVideoBackground';
import Text from 'antd/lib/typography/Text';
import Specs from './Specs';
import ContentSection from './ContentSection';

function SiteController() {
    const { Header, Content, Footer } = Layout;
    const { Title } = Typography;
    const [mem, setMem] = useState({selection:1,antSelection:2,  canChange : true});
    
    const getSelecionColor = (section) => {
        if(section != mem.selection){
            return "#555"
        }else{
            return "#FFF"
        }
    }
    const changeSection = (sectionID) => {
        if(sectionID==mem.selection){
            return 
        }
        if(mem.canChange == true ){
            const antSelection  = mem.selection;
            setMem({...mem,  antSelection, selection:sectionID,   canChange : false});
        }
            
        
    }
    useEffect(() => {
        changeSection(1)
    }, [])
    const changeNext = () => {
        if(mem.selection == 3){
            changeSection(1)
        }else{
            changeSection(mem.selection+1)
        }
    }
    const changeBack = () => {
        if(mem.selection == 1){
            changeSection(3)
        }else{
            changeSection(mem.selection-1)
        }
    }
    const onVideoComplete= (nextSection) => {
        setMem({...mem, canChange:true})
    }
    const onLoadVideo= (nextSection) => {

    }
    return (
        <Layout style={{height:"100vh", backgroundColor:"rgba(0, 0, 0, 0.0)"}}>
            <SiteVideoBackground currentSection={mem.antSelection} nextSection={mem.selection}  onLoadVideo={(nextSection)=>{onLoadVideo(nextSection)}}  onVideoComplete={(nextSection)=>{onVideoComplete(nextSection)}}/>
            <Specs section={mem.selection} />
            <Header style={{backgroundColor:"rgba(0, 0, 0, 0.0)", paddingTop:"40px", height:'150px'}}>
                <AnimateCC animationName="LogoAnimation" style={{width:'25%', maxWidth:'420px', minWidth:'300px', zIndex:'1001'}}/>          
              
            </Header>
            <Content style={{height:"100%", paddingLeft:"60px"}}>
                <Row style={{height:"100%"}} justify="start" align="middle">
                    <ContentSection section={mem.selection}/>
                </Row>
            </Content>
            <Footer style={{backgroundColor:"rgba(0, 0, 0, 0.0)"}}> 
                <Row>
                    <Col span={2}> 
                    </Col>
                    <Col> 
                    
                        <Row gutter={[8,48]}>
                            <Col span={24}>
                                <Space size="large">
                                    <Button className="LButtons" ghost  shape="circle" icon={<UpOutlined />} onClick={()=>{changeBack()}}/>
                                    <Button className="LButtons"  ghost  shape="circle" icon={<DownOutlined />} onClick={()=>{changeNext()}}/>                        
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space size="large" style={{color:"#ffffff"}}>
                                    <Text  style={{color:getSelecionColor(1)}} onClick={()=>{changeSection(1)}}>TECNOLOGÍA</Text>
                                    <Text style={{color:getSelecionColor(2)}} onClick={()=>{changeSection(2)}}>DISEÑO</Text>
                                    <Text style={{color:getSelecionColor(3)}} onClick={()=>{changeSection(3)}}>DESARROLLO DE NEGOCIOS</Text>
                                </Space>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                </Row>
            </Footer>
        </Layout>
        
    )
}

export default SiteController
