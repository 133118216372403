import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import "antd/dist/antd.dark.css";

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import webReducer from './redux/reducers/webReducer'
import promise from 'redux-promise'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SiteController from './site/SiteController';

const creteStoreWithMiddleWare = applyMiddleware(promise)(createStore); 

ReactDOM.render(
  <Provider store={creteStoreWithMiddleWare(webReducer)}>
  <BrowserRouter>

    <Route path="/" component={SiteController}/>
    <Route path="/index/" component={SiteController}/>
  </BrowserRouter>      
  

</Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
